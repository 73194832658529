import { render, staticRenderFns } from "./expired.vue?vue&type=template&id=105020f1"
import script from "./expired.ts?vue&type=script&language=ts&lang=js&external"
export * from "./expired.ts?vue&type=script&language=ts&lang=js&external"
import style0 from "./expired.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports