import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class ExpiredComponent extends Vue {

    created() {
        Debug.setDebugModule('expired', this);
     document.body.style.backgroundImage = 'url(/images/White.jpg)';
       
    }

    beforeDestroy() {
        document.body.style.backgroundImage = "";
    }

    mounted() {
    }
}
